<script>

export default {
  components: {  
    
  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
 
};
</script>

<template>
  <div>
    <i class="font-size-16 fa fa-spin fa-spinner" v-if="params.spinner && params.spinner(params.data)"></i>
    <div class="dropdown mb-0 text-center" v-if="!params.spinner || !params.spinner(params.data)">
      <a
        class="text-primary px-2 dropdown-toggle"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        v-if=" params.subItems &&  params.subItems.filter(x => !x.hidden || !x.hidden(params.data)).length"
      >
        <i class="bx bx-dots-vertical font-size-16"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-end">
        <template
          v-for="(subItem, $index) in params.subItems" 
          :key="$index" 
        >
          <a class="dropdown-item px-2 py-0" href="javascript:void(0)" 
            v-if="!subItem.hidden || !subItem.hidden(params.data)"
            :class="subItem.class || {}" 
            @click="subItem.onClick(params.data)"
          >
            <i :class="subItem.iconClass || {}"></i>
            <span>{{subItem.text}}</span>
          </a>
        </template>
      
      </div>
    </div>
  </div>
   
</template>
