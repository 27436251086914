<script>

export default {
  components: {  
    
  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
 
};
</script>

<template>
 <span>{{params.func(params.data)}}</span>
</template>
