<script>

export default {
  components: {  
    
  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
 
};
</script>

<template>
  <a href="javascript:void(0)"  class="text-primary"  v-if="params.onClick"  title="Details" @click="params.onClick(params.data)">
  <i class="bx bx-detail font-size-16"></i>
  </a>
  <router-link
    v-if="params.to"
    class="text-primary"  
    title="Details"
    :to="params.to(params.data)"
  >
    <i class="bx bx-detail font-size-16"></i>
  </router-link>  
</template>
