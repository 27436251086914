<script>
  /* eslint-disable vue/no-unused-components */

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue3";
import DetailsButtonRenderer from '@/components/ag-grid/renderers/details-button'; 
import DropDownButtonRenderer from '@/components/ag-grid/renderers/dropdown-button'; 
import CustomRenderer from '@/components/ag-grid/renderers/custom'; 
import HeaderRenderer from '@/components/ag-grid/renderers/header'; 
import UsersRenderer from './ag-grid/renderers/users.vue'; 
import StatusRenderer from './ag-grid/renderers/status.vue'; 
import { mapGetters, mapMutations, mapActions } from 'vuex';
import Swal from 'sweetalert2'


export default {
  components: {
    AgGridVue,
    detailsButtonRenderer: DetailsButtonRenderer,
    dropDownButtonRenderer: DropDownButtonRenderer,
    customRenderer: CustomRenderer,
    headerRenderer: HeaderRenderer,
    usersRenderer: UsersRenderer,
    statusRenderer: StatusRenderer
  },
  mounted () {
    window.addEventListener("resize", this.debCalculateHeight);
    this.debCalculateHeight();
  },
  unmounted() {
    window.removeEventListener("resize", this.debCalculateHeight);
  },
  data() {
    return {
     
    };
  },
  computed :{
    columnDefs() {
      const { pinnedColumns, columnsOrder, columnsWidth } = this;

      let res = [
        { 
          headerName: "", 
          field: "$details" , 
          width:  50, 
          pinned: true, 
          hide: false, 
          cellClass: 'first-button-cell',
          cellRenderer: 'detailsButtonRenderer', 
          cellRendererParams: { 
            onClick: (reminder) => {
              this.selectReminder({reminder});
            }
          }
        },
        { 
          headerName: "", 
          field: "$menu",
          width: 40, 
          pinned: true, 
          hide: false, 
          cellClass: 'dropdown-button-cell',
          cellRenderer: 'dropDownButtonRenderer', 
          cellRendererParams: { 
            spinner: (reminder) => reminder.id === this.processing,
            subItems:[
              { class: 'text-primary', iconClass:'bx bx-pencil  me-2', text: 'Edit', hidden: (reminder) => !reminder.allowEdit, onClick: (reminder) => this.onEdit( { reminder: reminder })},
              { class: 'text-warning', iconClass:'bx bx-trash-alt me-2', text: 'Cancel',hidden: (reminder) => !reminder.allowCancel, onClick: (reminder) => this.onCancel({ reminder: reminder })},
              { class: 'text-danger', iconClass:'bx bx-trash-alt me-2', text: 'Delete', hidden: (reminder) => !reminder.allowDelete, onClick: (reminder) => this.onDelete({ reminder: reminder })}
            ]
          }
        },
        { 
          headerName: "#", 
          field: "number" , 
          width: columnsWidth["number"] ?? 80, 
          pinned: pinnedColumns.find(x => x === "number") ? true : false, 
          resizable: true, 
          hide: false, 
          headerComponent: 'headerRenderer' 
        },
        
        { 
          headerName: "Date & Time", 
          field: "datetime",
          pinned: pinnedColumns.find(x => x === "datetime") ? true : false, 
          width: columnsWidth["datetime"] ?? 120, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'customRenderer',  
          cellRendererParams: {
            func: (reminder) => this.$filters.dateOnly(reminder.date) + ' ' + this.$filters.formatTimeOnly(reminder.time)
          }, 
          headerComponent: 'headerRenderer'
        },
        { 
          headerName: "Subject", 
          field: "subject", 
          width: columnsWidth["subject"] ?? 200, 
          pinned: pinnedColumns.find(x => x === "subject") ? true : false, 
          resizable: true, 
          hide: false, 
          headerComponent: 'headerRenderer' 
        },
         { 
          headerName: "Process", 
          field: "process", 
          width: columnsWidth["process"] ?? 150, 
          pinned: pinnedColumns.find(x => x === "process") ? true : false, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'processRenderer',
          headerComponent: 'headerRenderer' 
        },
       { 
          headerName: "Contact", 
          field: "contact", 
          width: columnsWidth["contact"] ?? 150, 
          pinned: pinnedColumns.find(x => x === "contact") ? true : false, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'contactRenderer',
          headerComponent: 'headerRenderer' 
        },
        { 
          headerName: "Users", 
          field: "users", 
          width: columnsWidth["users"] ??  200, 
          pinned: pinnedColumns.find(x => x === "users") ? true : false, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'usersRenderer',
          headerComponent: 'headerRenderer'
        },
        
        { 
          headerName: "Status", 
          field: "status", 
          pinned: pinnedColumns.find(x => x === "status") ? true : false, 
          width: columnsWidth["status"] ?? 150, 
          resizable: true, 
          hide: false, 
          cellRenderer: 'statusRenderer',
          headerComponent: 'headerRenderer' 
          },
        { 
          headerName: "Text", 
          field: "text", 
          pinned: pinnedColumns.find(x => x === "text") ? true : false, 
          width: columnsWidth["text"] ?? 300, 
          resizable: true, 
          hide: false, 
          headerComponent: 'headerRenderer' 
          }
      ];

      const origOrder = res.map(x => x.field);
      const o = [...res.filter(x => x.field !== '$details' && x.field !== '$menu')]
      o.sort((a, b) => {
        let ai = columnsOrder.indexOf(a.field);
        let bi = columnsOrder.indexOf(b.field);
        if(ai < 0) ai = origOrder.indexOf(a.field);
        if(bi < 0) bi = origOrder.indexOf(b.field);
        return ai > bi ? 1 : -1;
      });
      return [res[0], res[1], ...o];
    },
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('reminders', {
      items: 'items',
      deleting: 'deleting',
      processing: 'processing',
      pinnedColumns: 'pinnedColumns',
      columnsOrder: 'columnsOrder',
      columnsWidth: 'columnsWidth'
    }),
  },
  methods:{
    ...mapMutations('reminders', {
      onEdit: 'SET_EDITED_REMINDER',
      selectReminder: 'SELECT_REMINDER'
    }),
    ...mapActions('reminders', {
      setPinnedColumns: 'setPinnedColumns',
      setColumnsOrders: 'setColumnsOrders',
      setColumnsWidth: 'setColumnsWidth',
      deleteReminder: 'deleteReminder',
      cancelReminder : 'cancelReminder'
    }),
    debCalculateHeight() {
      this.$nextTick(()=> this.calculateHeight());
      window.setTimeout(() => {
         this.calculateHeight()
      }, 1000);
    },
    calculateHeight() {
      const table = document.getElementById('reminders-table') || this.$refs['table'];
      const windowHeight = window.innerHeight;
      const toolbar = document.getElementById("user-toolbar");
      const topBar = document.getElementById("page-topbar");

      if(table && toolbar) {
        const o = topBar.offsetHeight + toolbar.offsetHeight 
        + 40 //paddings
        ;
        const boardMaxHeight = windowHeight - o; // topbar + paddings

        table.style.maxHeight = boardMaxHeight + 'px';  
        table.style.height = boardMaxHeight + 'px';  
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    onColumnResized(params) {
      const columns = params.columnApi.getAllGridColumns().filter(x => x.colDef.field !== '$details' && x.colDef.field !== '$menu');
      const o = {};
      columns.forEach(column => {
        const width = column.actualWidth;
        o[column.colDef.field] = width;
      });
      this.setColumnsWidth(o);
    },
    onColumnMoved (params) {
      const columns = params.columnApi.getAllGridColumns().filter(x => x.colDef.field !== '$details' && x.colDef.field !== '$menu');
      const columnsOrder= columns.map(x => x.colDef.field);
      this.setColumnsOrders(columnsOrder);
    },
    onColumnPinned(params) {
      const columns = params.columnApi.getAllGridColumns();
      const pinnedColumns = columns.filter(x => x.isPinned() && x.colDef.field !== '$details' && x.colDef.field !== '$menu')
      const pinnedIdColumns=pinnedColumns.map(x => x.colDef.field);
      
      this.setPinnedColumns(pinnedIdColumns);
    },

    onDelete( { reminder } = {}) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.deleteReminder({id: reminder.id }).then(()=>{
            Swal.fire("Deleted!", "Reminder has been deleted.", "success");
          });
        }
      });
    },
     onCancel( { reminder } = {}) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.value) {
          this.cancelReminder({id: reminder.id }).then(()=>{
            Swal.fire("Cancelled!", "Reminder has been cancelled.", "success");
          });
        }
      });
    }
  }
};
</script>

<template>
 <div class="m-0">
    <ag-grid-vue
      ref="table"
      id="reminders-table"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      @grid-ready="onGridReady"
      @column-resized="onColumnResized"
      @column-moved="onColumnMoved"
      @column-pinned="onColumnPinned"
      :rowData="items"
    >
    </ag-grid-vue>
  </div>
</template>
