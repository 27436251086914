<script>
import ReminderStatus from "../../status.vue"

export default {
  components: {  
    ReminderStatus
  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
  computed: {
    reminder() {
      return this.params.data;
    }
  }
};
</script>

<template>
  <reminder-status :reminder="reminder"></reminder-status>
</template>
