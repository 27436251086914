<script>
import Layout from "../../layouts/main";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import List from './list';
import FilterRightSidebar from "./filter-right-sidebar";
import Pagination from '@/components/pagination';

export default {
  components: {
    Layout,
    List,
    FilterRightSidebar,
    Pagination
  },
  data() {
    return {
      pagetitle: "Reminders",
      initializing: true
    };
  },
  created() {
    this.init();
  },
  computed :{
    ...mapGetters('reminders', {
      page: 'page',
      orderBy: 'orderBy',
      perPage: 'perPage',
      total: 'total',
      loading: 'loading'
    }),
     ...mapGetters('reminders', {
      filterEnabled: 'filterEnabled',
      version: 'version'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    isBusy () {
      return this.loading;
    },
    orderByAsc:
    {
      get: function() {
        return this.orderBy.asc || false;
      },
      set: function(newValue) {
        this.setOrderBy({...this.orderBy, asc: newValue});
      }
    },
    orderByField:
    {
      get: function() {
        return this.orderBy.field || false;
      },
      set: function(newValue) {
        this.setOrderBy({...this.orderBy, field: newValue});
      }
    },
    currentPage:
    {
      get: function() {
        return this.page || 1;
      },
      set: function(newValue) {
        this.setPage(newValue);
      }
    },
  },
  watch:{
    version(newValue, oldValue) {
      if(newValue != oldValue) {
        this.load({ resetPage: true});
      }
    }
  },
  methods: {
    ...mapMutations('reminders', {
      beginEdit: 'SET_EDITED_REMINDER',
    }),
    ...mapActions('reminders', {
      loadPageSettings: 'loadPersistent',
      load: 'load',
      setOrderBy: 'setOrderBy',
      setPage: 'setPage'
    }),
    async init() {
      this.initializing = true;
      await this.loadPageSettings();
      await this.load();
      this.initializing = false;
    },
    toggleFilter () {
      document.body.classList.toggle("right-bar-enabled");
    },
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle" :is-busy="isBusy">
    <filter-right-sidebar></filter-right-sidebar>
    <div class="row" v-if="!initializing" ref="toolbar" id="user-toolbar">
      <div class="col-md-6">
         <pagination :page="currentPage" :total="total" :per-page="perPage" @change="(v) => currentPage = v"/>
      </div>
      <div class="col-md-6">
        <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
          <div class="me-2  mb-1">
           <button
              type="button"
              class="btn btn-primary"
               @click="beginEdit({ reminder: {}})"
              > 
                <eva-icon class="icon nav-icon me-1" name="plus-outline"></eva-icon>
                <div class="text-sm-end d-none d-md-inline-block ms-2">
                  New
                </div>
            </button>
          </div>
          <div class="me-2 mb-1">
            <div class="dropdown d-inline-block">
              <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div class="text-sm-end d-none d-md-inline-block ms-2">
                    <span class="me-2">
                      <span v-if="orderByField === 'Date'">Date</span>
                      <span v-if="orderByField === 'Contact'">Contact</span>
                      <span v-if="orderByField === 'Number'">Number</span>
              
                    </span>
                  </div>
                  <i class="fas  fa-sort-alpha-up" v-if="orderByAsc"></i>
                  <i class="fas  fa-sort-alpha-down" v-if="!orderByAsc"></i>

                  <i class="fa-caret-down fa ms-1"></i>
                </button>
              <div class="dropdown-menu dropdown-menu-end pt-2">
               
                <a class="dropdown-item" :class="{'active': orderByField === 'Number'}" href="javascript:void(0)"  @click="orderByField = 'Number'">
                  <i class="mdi mdi-check me-1" v-if="orderByField === 'Number'"></i>
                  <i class="mdi mdi-checkbox-blank-circle-outline me-1"  v-if="orderByField !== 'Number'"></i>
                  Number
                </a>
                <a class="dropdown-item" :class="{'active': orderByField === 'Date'}" href="javascript:void(0)"  @click="orderByField = 'Date'">
                  <i class="mdi mdi-check me-1" v-if="orderByField === 'Date'"></i>
                  <i class="mdi mdi-checkbox-blank-circle-outline me-1"  v-if="orderByField !== 'Date'"></i>
                  Date
                </a>
                <a class="dropdown-item" :class="{'active': orderByField === 'Contact'}" href="javascript:void(0)"  @click="orderByField = 'Contact'">
                  <i class="mdi mdi-check me-1" v-if="orderByField === 'Contact'"></i>
                  <i class="mdi mdi-checkbox-blank-circle-outline me-1"  v-if="orderByField !== 'Contact'"></i>
                  Contact
                </a>
               
                <hr/>
                <div class="text-center mb-2"> 
                  <div class="btn-group" role="group">
                    <button class="btn btn-sm" :class="{'btn-outline-primary': !orderByAsc, 'btn-primary': orderByAsc}" @click="orderByAsc =  true">
                      <i class="fas fa-sort-alpha-up me-2"></i>Asc
                    </button>
                    <button class="btn btn-sm" :class="{'btn-outline-primary': orderByAsc, 'btn-primary': !orderByAsc}" @click="orderByAsc =  false">
                      <i class="fas fa-sort-alpha-down me-2"></i>Desc
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="mb-1">
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn btn-secondary"
               
                @click.stop.prevent="toggleFilter(slotProps)"
              >
                <i class="fa fa-filter "></i>
                <div class="text-sm-end d-none d-md-inline-block ms-2">
                  Filters
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white" style="margin-top:10px">
      <list  v-if="!initializing"></list>
    </div>
  </Layout>
</template>
