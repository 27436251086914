<script>
import { mapGetters, mapActions } from 'vuex';
import UserSelection from '@/components/user-selection';

export default {
  components: {
    UserSelection
  },
   props: {
    stateHidden: {
      type: Boolean
    }
  },
  computed :{
    ...mapGetters('users', {
      allUsers: 'itemsOnlyAlive'
    }),
    ...mapGetters('reminders', {
      filter: 'filter'
    }),
    users: {
       get: function() {
        return ((this.filter || {}).users || []).filter(x => this.allUsers.find(a => a.id === x)).map(x => this.allUsers.find(a => a.id === x));
      },
      set: function(newValue) {
        const data = this.cloneFilter();
        data['users'] = (newValue || []).map(x => x.id);
        this.setFilter({data});
      }
    },
    status: {
      get: function() {
        const a = (this.filter || {}).status || '';
        return a;
      },
      set: function(newValue) {
       
        const data = this.cloneFilter();
        data['status'] = newValue || '';
        this.setFilter({data});
      }
    },
    dateTill: {
      get: function() {
        return (this.filter || {}).dateTill || null;
      },
      set: function(newValue) {
        
        const data = this.cloneFilter();
        data['dateTill'] = newValue || null;
        this.setFilter({data});
      }
    },
    dateFrom: {
      get: function() {
        return (this.filter || {}).dateFrom || null;
      },
      set: function(newValue) {
       
        const data = this.cloneFilter();
        data['dateFrom'] = newValue || null;
        this.setFilter({data});
      }
    },
    keyword:
    {
      get: function() {
        return (this.filter || {}).keyword || "";
      },
      set: function(newValue) {
        const data = this.cloneFilter();
        data['keyword'] = newValue;
        this.setFilter({data});
      }
    },
  },
  methods:{
    ...mapActions('reminders', {
      setFilter: 'setFilter',
      resetFilter: 'resetFilter'
    }),
    cloneFilter(){
      return {...this.filter};
    }
  }
};
</script>

<template>
  <div>
    <div class="form-group mb-3 mt-3">
      <label for="Manager-input" class="col-form-label">Keyword</label>
      <input type="text" 
        v-model="keyword"
        class="form-control"     
      />
    </div>
    <div class="form-group mb-3 mt-3">
      <label for="Manager-input" class="col-form-label">Status</label>
      <span v-if="status === null">AAA</span>
      
      <select 
        v-model="status"
        class="form-control"     
      >
        <option value="">All</option>
        <option value="Active">Active</option>
        <option value="Upcoming">Upcoming</option>
        <option value="Handled">Handled</option>
        <option value="Cancelled">Cancelled</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="Manager-input" class="col-form-label">User</label>
      <div>
        <user-selection 
          :value="users" 
          @update="v => users = v"
        >
        </user-selection>
      </div>
    </div>

    <div class="form-group mb-3">
      <label  class="col-form-label">Date</label>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-3"> 
              <small><label>From</label></small>
            </div>
            <div class="col-9">
              <input 
                type="date" 
                class="form-control mb-2" 
                placeholder="From"
                v-model="dateFrom"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-3"> 
              <small><label>Till</label></small>
            </div>
            <div class="col-9">
              <input 
                type="date" 
                class="form-control" 
                placeholder="Till"
                v-model="dateTill"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="mb-3 mt-4 text-right">
      <button class="btn btn-light" @click="resetFilter">Reset</button>
    </div>
  </div>
</template>
