<script>

export default {
  components: {  
  
  },
  created() {
    
  },
  props: {
    params: {
      type: Object
    }
  },
  computed: {
    users() {
      return this.params.data.users;
    }
  }
};
</script>

<template>
  <span>
    <span v-for="user in users" :key="user.id" class="p-1  me-2">
      {{user.user.name}}
    </span>
  </span>
 
</template>
