<script>

export default {
  components: {  
    
  },
  created() {
    
  },  
  props: {
    params: {
      type: Object
    }
  },
  computed: {
    def() {
      return this.params.column.colDef;
    },
    isPinned () {
      return this.params.column.isPinned();
    }
  },
  methods: {
    togglePin() {
      if(this.isPinned) {
        this.params.columnApi.setColumnPinned( this.params.column, false);
      } else {
        this.params.columnApi.setColumnPinned( this.params.column, true);
      }
    }
  }
};
</script>

<template>
  <div >
  
  <span>
    {{ params.displayName }}
  </span>
   
  </div>
</template>
